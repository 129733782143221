

















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import FundManagerCard from "@/modules/genprox/modules/AIFM/components/FundManagerCard.vue";
import FundKPIs from "@/modules/genprox/modules/AIFM/components/FundKPIs.vue";
import FundFinancials from "@/modules/genprox/modules/AIFM/components/FundFinancials.vue";
import FundChart from "@/modules/genprox/modules/AIFM/components/FundChart.vue";
import Chart from "@/modules/genprox/modules/AIFM/components/Chart.vue";
import NewChart from "@/modules/genprox/modules/AIFM/components/NewChart.vue";
import FundProducts from "@/modules/genprox/modules/AIFM/components/FundProducts.vue";
import FundTransactions from "@/modules/genprox/modules/AIFM/components/FundTransactions.vue";
import ManagementTeam from "@/modules/genprox/modules/AIFM/components/ManagementTeamCard.vue";
import FundInformationCard from "@/modules/genprox/modules/AIFM/components/FundInformationCard.vue";
import FundDataCard from "@/modules/genprox/modules/AIFM/components/FundDataCard.vue";
import FundHeader from "@/modules/genprox/modules/AIFM/components/FundHeader.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import { mapState } from "vuex";
import { Prop, Watch } from "vue-property-decorator";
import { FundManager, MTMember } from "@/modules/genprox/models/User";
import { Report } from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import { KPIs } from "@/modules/genprox/modules/profile/models/KPIs.interface";
import Utils from "@/modules/shared/utils/utils";
import { FundData } from "@/modules/genprox/models/User";
import moment from 'moment';
import { fundFinancialsInterface } from "@/modules/genprox/modules/profile/models/fundFinancials.interface";
import { LastTransactionsInterface } from "@/modules/genprox/modules/profile/models/lastTransactions.interface";
import ContextHelper from "@/modules/shared/utils/ContextHelper";

@Component({
  components: {
    FundInformationCard,
    FundDataCard,
    ManagementTeam,
    FundManagerCard,
    SygniContainerTitle,
    FundKPIs,
    FundFinancials,
    FundChart,
    FundProducts,
    FundTransactions,
    FundHeader,
    SygniLoader,
    Chart,
    NewChart
  },
  computed: {
    ...mapState('genprox', {
      managementTeam: (state: any) => state.managementTeam,
      activeUserContextId: (state: any) => state.activeUserData.context?.id
    }), ...mapState('regReporting', {
      fundManagerData: (state: any) => state.fundData,
      reports: (state: any) => state.reports.sort((reportA: Report, reportB: Report) => {
        return reportA.year < reportB.year ? 1 : -1;
      })
    })
  }
})
export default class RegReportingModule extends Vue {
  @Prop() openCardId!: string;

  fundManagerData!: FundManager;
  accessModules: any = null;
  progressMax: number = 0;
  progressVal: number = 0;
  filters: any = {
    date: {
      value: '',
      placeholder: '',
    },
    type: 'all',
    company: '',
  };
  isLoading: boolean = true;
  managementTeam!: MTMember[];
  reports!: Array<Report>;
  activeUserContextId!: string;
  fundInfo: FundData = {
    programme: null,
    commencementDate: null,
    investmentAgreementDate: null,
    endOfInvestmentPeriod: null,
    fundClosingDate: null,
    capitalisation: null,
    fundManager: null,
    publicInvestor: null,
    privateInvestor: null,
    investmentBudget: null,
    operationalBudget: null
  };
  cumulatedDeals: { x: string, y: number }[] = [];
  showFundKPIs: boolean = false;
  showFundFinancials: boolean = false;
  showTransactions: boolean = false;
  showCumulatedDeals: boolean = false;
  fundKPIs: KPIs = {
    invested: null,
    portfolioValuation: null,
    companyCount: null,
    dealCount: null
  };
  newGraphs: any[] = []
  fundAdditionalKPIs: any = null
  fundFinancials: fundFinancialsInterface = {
    date: '',
    periodType: '',
    data: [
      [
        {
          name: "Investment assets",
          level: 1,
          value: null
        },
        {
          name: "Cash level",
          level: 1,
          value: null
        },
        {
          name: "Non-investment assets",
          level: 1,
          value: null
        },
        {
          name: "Total Assets",
          level: 0,
          value: null
        }
      ],
      [
        {
          name: "Capital and Reserves",
          level: 1,
          value: null
        },
        {
          name: "Long-term liabilities",
          level: 1,
          value: null
        },
        {
          name: "Short-term liabilities & Provisions",
          level: 1,
          value: null
        },
        {
          name: "Total Liabilities",
          level: 0,
          value: null
        }
      ]
    ]
  };
  transactions: LastTransactionsInterface[] | boolean = false;
  widgets: any[] = []

  get companyOptions() {
    return this.$store.getters['auth/getInvestedCompanies'];
  }

  get fundOptions() {
    return this.$store.getters['auth/getInvestedFunds'];
  }

  get useViewedId() {
    if (this.isPortfolioCompany) {
      if (this.activeUserData.context.context === 'company') {
        return false;
      } else {
        return true
      }
    } else {
      if (this.activeUserData.context.context === 'fund') {
        return false;
      } else {
        return true
      }
    }
  }

  get showLePicker() {
    if (this.isPortfolioCompany) {
      if (this.activeUserData.context.context === 'company') {
        return false;
      } else {
        if (this.companyOptions.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (this.activeUserData.context.context === 'fund') {
        return false;
      } else {
        if (this.fundOptions.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  get nameSymbol(): string {
    return this.fundManagerData.name?.slice(0, 1);
  }

  get viewedId() {
    return this.activeUserData.context.context !== 'fund' ? this.$store.getters['auth/getViewedFundId'] : this.activeUserContextId;
  }

  get isFund() {
    return this.activeUserData.context.context === 'fund';
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('company') ? true : false;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get activeUserRoles() {
    return this.$store.getters['genprox/activeUserData'].role;
  }

  userHasAccessTo(type: string | string[]) {
    let result = false;

    if (type === 'reporting') {
      if (this.activeUserData.role.reporting !== null) result = true;
    }
    if (type === 'transaction') {
      if (this.activeUserData.role.transaction !== null) result = true;
    }

    return result;
  }

  get isFundManager() {
    return ContextHelper.isActiveContextFundManager
  }

  async getAccessToModule(type: string) {
    if (this.isFundManager) {
      return true
    }

    if (this.isFund) {
      if (type === 'transaction') {
        return true
        // return this.activeUserRoles.transaction !== null;
      }
      if (type === 'reporting') {
        return this.activeUserRoles.reporting !== null;
      }
    } else {
      if (this.accessModules === null) {
        this.accessModules = await this.$store.dispatch('genprox/getLegalEntityAccessModules', this.viewedId);
      }

      if (type === 'transaction') {
        return this.userHasAccessTo('transaction') ? this.userHasAccessTo('transaction') : false;
      }
      if (type === 'reporting') {
        return this.userHasAccessTo('reporting') ? this.userHasAccessTo('reporting') : false;
      }
    }
  }

  async getDashboardWidgets() {
    this.widgets = await this.$store.dispatch('genprox/getFundWidgets', this.useViewedId ? this.viewedId : '')
    this.newGraphs = await this.$store.dispatch('genprox/getReportingGraphs', this.useViewedId ? this.viewedId : '')
  }

  async updateData(date?: string) {
    this.isLoading = true;
    const searchedDate = date ? date : moment().format('YYYY-MM-DD');
    const type = this.filters.type === 'all' ? '' : this.filters.type;

    this.showFundKPIs = await this.getAccessToModule('transaction');
    
    if (this.showFundKPIs) {
      if (this.$route.path?.includes('/fund/dashboard')) {
        this.fundAdditionalKPIs = await this.$store.dispatch('genprox/getFundKPIs', { searchedDate, type, viewedContext: this.useViewedId ? this.viewedId : '' })
      }
      
      const fundKPIs = await this.$store.dispatch('genprox/getKPIs', {
        searchedDate,
        type,
        path: 'fund',
        viewedContext: this.useViewedId ? this.viewedId : ''
      });

      if (fundKPIs) {
        this.fundKPIs = fundKPIs;
      } else {
        this.fundKPIs.invested = null;
        this.fundKPIs.portfolioValuation = null;
        this.fundKPIs.companyCount = null;
        this.fundKPIs.dealCount = null;
      }
    }

    this.showFundFinancials = await this.getAccessToModule('reporting');
    if (this.showFundFinancials) {
      const fundFinancials = await this.$store.dispatch('genprox/getKeyFinancials', {
        date: searchedDate,
        path: 'fund',
        viewedContext: this.useViewedId ? this.viewedId : '',
      });
      if (fundFinancials) {
        this.fundFinancials = fundFinancials;
      } else {
        this.fundFinancials.data.forEach(el => {
          el.forEach((i: any) => {
            i.value = null;
          })
        })
      }
    }

    this.showTransactions = await this.getAccessToModule('transaction');
    if (this.showTransactions) {
      const transactions = await this.$store.dispatch('genprox/getLastTransactions', {
        searchedDate,
        type,
        path: 'fund',
        viewedContext: this.useViewedId ? this.viewedId : '',
      });
      if (transactions) {
        this.transactions = transactions;
      } else {
        this.transactions = [];
      }
    }

    this.progressVal = this.fundKPIs.invested == null ? 0 : this.fundKPIs.invested.value;
    this.progressMax = this.fundInfo.capitalisation == null ? this.progressVal : this.fundInfo.capitalisation.value;
    this.isLoading = false;
  }

  async updateCompany() {
    await this.updateData();
  }

  setDataDashboard(date: any): void {
    let dateTime = new Date(date);
    dateTime = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
    // date range definition
    const dateRange = [date, `${dateTime.getFullYear()}-${Utils.pad(dateTime.getMonth() + 1, 2)}-${Utils.pad(dateTime.getDate(), 2)}`];
    this.filters.date.value = dateRange;
    const filteredDate = dateRange[1];
    this.updateData(filteredDate);
  }

  async getCumulatedDeals() {
    this.showCumulatedDeals = await this.getAccessToModule('transaction');
    if (this.showCumulatedDeals) {
      const type = this.filters.type === 'all' ? '' : this.filters.type;
      const cumulatedDeals = await this.$store.dispatch('genprox/getDealCounts', { type, path: 'fund', viewedContext: this.useViewedId ? this.viewedId : '', });
      if (cumulatedDeals) this.cumulatedDeals = cumulatedDeals;
    }
  }

  setDatePlaceholder() {
    const date = this.filters.date.value[0];
    let dateTime = new Date(date);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = monthNames[dateTime.getMonth()];
    this.filters.date.placeholder = `${month}, ${dateTime.getFullYear()}`;
  }

  @Watch('filters.date.value') onDateChange() {
    this.setDatePlaceholder();
  }

  @Watch('filters.type') onTypeChange() {
    this.getCumulatedDeals();
    this.getDashboardWidgets();
    this.updateData();
  }

  async loadData() {
    if (!this.isPortfolioCompany) {
      if (this.activeUserData.context.context != 'fund') {
        await this.$store.dispatch('auth/getViewedFund');
        await this.$store.dispatch('auth/getInvestedFunds');
      }
    } else {
      if (this.activeUserData.context.context != 'company') {
        await this.$store.dispatch('auth/getViewedCompany');
        await this.$store.dispatch('auth/getInvestedCompanies');
      }
    }

    await this.$store.dispatch('genprox/getCompanyWidgets')

    this.setDataDashboard(moment().format('YYYY-MM-DD'));
    this.$store.dispatch('genprox/getManagementTeam', this.viewedId);
    const fundData: FundManager = await this.$store.dispatch('genprox/getLeContextData', this.viewedId);
    await this.$store.commit('regReporting/setFundData', fundData);

    const fundInfo = await this.$store.dispatch('genprox/getFundData');

    if (fundInfo) this.fundInfo = fundInfo

    await this.getCumulatedDeals();
    await this.getDashboardWidgets();

    // await this.updateData();
  }

  async mounted() {
    if(this.$route?.params?.id) {
      (this.$refs.fundHeader as FundHeader).setViewedId(this.$route.params.id);
    }
    await this.loadData();
  }

  async reloadDashboard() {
    this.isLoading = true;
    await this.loadData();
  }

  addNewReport(): void {
    this.$router.push('reg-reporting/add-report');
  }
}

